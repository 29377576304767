@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "SF Pro Display";
  src: url(../public/fonts/SF-Pro-Display-Light.otf);
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "SF Pro Display";
  src: url(../public/fonts/SF-Pro-Display-Regular.otf);
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "SF Pro Display";
  src: url(../public/fonts/SF-Pro-Display-Medium.otf);
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "SF Pro Display";
  src: url(../public/fonts/SF-Pro-Display-Semibold.otf);
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "SF Pro Display";
  src: url(../public/fonts/SF-Pro-Display-Bold.otf);
  font-weight: 800;
  font-display: swap;
}

* {
  scroll-margin-top: 48px;
}

@media screen and (min-width: 768px) {
  * {
    scroll-margin-top: 0;
  }
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: "SF Pro Display", Arial, sans-serif;
  letter-spacing: 0.02em;

  width: var(--w-screen);

  color: var(--color-blue-900);
  background: var(--color-background);
  -webkit-tap-highlight-color: transparent;
  -webkit-overflow-scrolling: touch;
}

a {
  color: inherit;
  text-decoration: none;
}

img {
  user-select: none;
}

svg {
  user-select: none;
}

audio::-webkit-media-controls {
  display: none;
}

.markdown-container h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 18px;
  font-weight: 600;
}

.markdown-container p {
  padding-bottom: 10px;
  line-height: 23px;
}

.markdown-container strong {
  font-size: 16px;
  font-weight: 700;
}

.markdown-container ul li {
  list-style-type: disc;
  margin-left: 15px;
  line-height: 23px;
  padding-bottom: 20px;
}

.popup-markdown-container strong {
  font-size: 12px;
  font-weight: 700;
}

* {
  box-sizing: border-box;
}

::selection {
  background-color: var(--color-selection);
}

:root {
  --color-white: #ffffff;
  --vh: 100%;
}

.light {
  --color-background: #f7f6fa;
  --color-background-cell: #ffffff;
  --color-background-surface-cell: #ffffff;
  --color-background-surface: #f1f0f5;
  --color-dashboard-bg: #ffffff;
  --color-sidebar-bg: #f4f3f7;
  --color-inactive-text: #ffffff;

  --color-selection: #c1ddfb;
  --color-skeleton: #e0e2eb;

  --color-popup-background: rgba(20, 17, 26, 0.4);
  --color-popup-cell: #fbfafd;

  --color-tooltip: #343641;

  --color-separator: #19171c14;
  --color-footer: #e7e9f3;

  --color-warning: #4d76f6;
  --color-error: #fd2e2e;
  --color-success: #09c185;
  --color-success-15: #09c18526;
  --color-info: rgba(18, 18, 18, 0.9);
  --color-link: #2789fb;

  --color-placeholder: #9195ab;

  --color-chat: #7d5df6;
  --color-chat-alpha: #7d5df626;

  --color-blue-50: #faf9fb;
  --color-blue-150: #e5e3ed;
  --color-blue-100: #eeecf3;
  --color-blue-200: #e0dde9;
  --color-blue-300: #c7c2d6;
  --color-blue-400: #ada5c0;
  --color-blue-500: #857d9b;
  --color-blue-600: #615c70;
  --color-blue-700: #464350;
  --color-blue-800: #2f2d35;
  --color-blue-900: #19171c;
  --color-blue-cell: #f5f6fc;

  --color-green-50: #e7f3ed;
  --color-green-100: #c8e4d6;
  --color-green-200: #aad5bf;
  --color-green-300: #8bc6a8;
  --color-green-400: #6cb791;
  --color-green-500: #51a47a;
  --color-green-600: #3b7859;
  --color-green-700: #33664d;
  --color-green-800: #234735;
  --color-green-900: #14291f;

  --color-primary-50: #f4f0fe;
  --color-primary-100: #e3d8fd;
  --color-primary-200: #b699fa;
  --color-primary-300: #976df8;
  --color-primary-400: #7c4ceb;
  --color-primary-500: #7842f5;
  --color-primary-600: #6435d0;
  --color-primary-700: #452a83;
  --color-primary-800: #331e61;
  --color-primary-900: #21143e;

  --color-accent-yellow: #ffd700;
  --color-accent-coral: #ff69b4;
  --color-accent-peach: #fd8656;
  --color-accent-blue: #40e0d0;

  --color-round: #ededed;

  --color-categories-text: #be3675;
  --color-categories-background: #f7e8ef;

  --color-subcategories-text: #c48039;
  --color-subcategories-background: #faeae6;

  --color-creators-text: #5048af;
  --color-creators-background: #edeafc;

  --color-daily-text: #1f7b8f;
  --color-daily-background: #dcf2ef;

  --color-big-bubble: #f4e1ee;

  --color-accent-1: #d2efeb;
  --color-accent-2: #dfd4f7;
  --color-accent-3: #fbdbef;
  --color-accent-4: #ffe7d1;
  --color-accent-5: #ccccff;
  --color-accent-6: #bcf6c5;
  --color-accent-7: #fdbec8;
  --color-accent-8: #facfa7;
  --color-accent-9: #e6ecf9;

  --color-new-blue: #eaf3fc;
}

.dark {
  --color-background: #111014;
  --color-background-cell: #1e1d20;
  --color-background-surface-cell: #1b1a1e;
  --color-background-surface: #34313a;
  --color-dashboard-bg: #111014;
  --color-sidebar-bg: #25232b;
  --color-inactive-text: #ffffff4D;

  --color-selection: #36567d;
  --color-skeleton: #242529;

  --color-popup-background: rgba(22, 21, 25, 0.8);
  --color-popup-cell: #27262c;

  --color-tooltip: #ecedef;

  --color-separator: #faf9fb0d;
  --color-footer: #181920;

  --color-warning: #94adfa;
  --color-error: #fd5e5e;
  --color-success: #09c185;
  --color-success-15: #09c18526;
  --color-info: #666666;
  --color-link: #2789fb;

  --color-placeholder: #6c6e7a;

  --color-chat: #7d5df6;
  --color-chat-alpha: #7d5df626;

  --color-blue-50: #1e1c22;
  --color-blue-150: #3d3b45;
  --color-blue-100: #2f2d35;
  --color-blue-200: #464350;
  --color-blue-300: #615c70;
  --color-blue-400: #857d9b;
  --color-blue-500: #ada5c0;
  --color-blue-600: #c7c2d6;
  --color-blue-700: #e0dde9;
  --color-blue-800: #ebe9f1;
  --color-blue-900: #faf9fb;
  --color-blue-cell: #383842;

  --color-green-50: #14291f;
  --color-green-100: #234735;
  --color-green-200: #33664d;
  --color-green-300: #3b7859;
  --color-green-400: #51a47a;
  --color-green-500: #6cb791;
  --color-green-600: #8bc6a8;
  --color-green-700: #aad5bf;
  --color-green-800: #c8e4d6;
  --color-green-900: #e7f3ed;

  --color-primary-50: #241e33;
  --color-primary-100: #392f51;
  --color-primary-200: #493578;
  --color-primary-300: #5c409c;
  --color-primary-400: #6739d0;
  --color-primary-500: #7842f5;
  --color-primary-600: #976df8;
  --color-primary-700: #b699fa;
  --color-primary-800: #d5c5fc;
  --color-primary-900: #f4f0fe;

  --color-accent-yellow: #ffc700;
  --color-accent-coral: #ff57a5;
  --color-accent-peach: #ff8a66;
  --color-accent-blue: #37c4b3;

  --color-round: #404040;

  --color-categories-text: #e39cbd;
  --color-categories-background: #460b29;

  --color-subcategories-text: #dfb890;
  --color-subcategories-background: #45180c;

  --color-creators-text: #cfccea;
  --color-creators-background: #261b65;

  --color-daily-text: #afe3ee;
  --color-daily-background: #163c36;

  --color-big-bubble: #260d1e;

  --color-accent-1: #18443e;
  --color-accent-2: #2d1b51;
  --color-accent-3: #520a37;
  --color-accent-4: #584431;
  --color-accent-5: #3b3b99;
  --color-accent-6: #509b5c;
  --color-accent-7: #994f5b;
  --color-accent-8: #9b7450;
  --color-accent-9: #192848;

  --color-new-blue: #1f272e;
}

:root {
  --layout-type: "desktop";

  --color-general-1: #ff3166;
  --color-general-2: #ff527e;
  --color-general-3: #ff618a;
  --color-general-4: #ff80a1;
  --color-general-5: #ffadc3;
  --color-general-6: #ffdbe5;
  --color-general-7: #fff5f8;
}

@layer components {
  .text-h1 {
    @apply text-[24px] font-800 tablet:text-[32px] desktop:text-[48px];
  }

  .text-h2 {
    @apply text-[22px] font-800 tablet:text-[28px] desktop:text-[44px];
  }

  .text-h3 {
    @apply text-[24px] font-800 tablet:text-[26px] desktop:text-[36px];
  }

  .text-h4 {
    @apply text-[24px] font-700 tablet:text-[26px] desktop:text-[36px] desktop:font-800;
  }

  .text-h5 {
    @apply text-[22px] font-700 tablet:text-[22px] desktop:text-[28px];
  }

  .text-h6 {
    @apply text-[18px] font-700 tablet:text-[20px] tablet:font-800 desktop:text-[24px] desktop:font-700;
  }

  .text-title {
    @apply text-[20px] font-700 desktop:text-[24px];
  }

  .text-subtitle {
    @apply text-[18px] font-700 uppercase;
  }

  .text-body-1 {
    @apply text-[18px] font-500 tablet:font-400 desktop:text-[24px] desktop:font-700;
  }

  .text-body-2 {
    @apply text-[18px] font-500 tablet:text-[20px] desktop:text-[22px];
  }

  .text-body-3 {
    @apply text-[18px] font-500 desktop:text-[20px];
  }

  .text-body-4 {
    @apply text-[16px] font-400 desktop:text-[18px];
  }

  .text-body-5 {
    @apply text-[16px] font-400;
  }

  .wrapped {
    @apply mx-auto w-full px-[16px] tablet:w-[768px] tablet:px-[20px] desktop:w-[1025px] desktop:px-[25px];
  }
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.scrollbar {
  scrollbar-color: var(--color-blue-300);
  scrollbar-width: 4px;
  border-radius: 8px;
}

.scrollbar::-webkit-scrollbar {
  width: 4px;
  border-radius: 8px;
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: var(--color-blue-300);
  border-radius: 8px;
}

.inline-placeholder:empty::before {
  content: attr(data-placeholder);
  color: var(--color-placeholder);
}

html,
body {
  --w-screen: calc(100vw - var(--scrollbar-width));
}

[data-scroll="false"] {
  overflow: hidden;
}

.mdx ul ol {
  padding-left: 1.5em;
}

.mdx ol ul {
  padding-left: 1.5em;
}

.mdx ol > li {
  list-style-type: decimal !important;
  list-style-position: inside;
}

.mdx ol > li::marker {
  color: #ff3166;
  font-weight: 700;
}

.mdx ul > li::before {
  content: "◆";
  color: #ff3166;
  font-weight: 800;
  display: inline-block;
  width: 1em;
  margin: 0 6px;
}

/* Prevent image source mismatch */
.dark [data-hide-on-theme="dark"],
.light [data-hide-on-theme="light"] {
  display: none;
}

/* Hide arrows for number input fields */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.half-spin {
  animation: half-spin 1s ease-in-out;
}

.before-line {
  top: 0;
  left: 50%;
  width: 2px;
  height: 100%;
  margin-left: -1px;
  border-radius: 5px;
}

.after-line {
  top: 50%;
  left: 0;
  width: 100%;
  height: 2px;
  margin-top: -1px;
  border-radius: 5px;
}

@media (max-height: 900px) and (min-width: 768px) {
  .responsive-zoom {
    zoom: 0.8;
  }
}

.markdown-wizard a {
  color: var(--color-link);
}

.markdown-wizard {
  position: relative;
}

.markdown-wizard a:hover {
  text-decoration-line: underline;
}

.markdown-wizard a::after {
  content: attr(href);
  background: var(--color-tooltip);
  color: var(--color-blue-100);
  font-size: 12px;
  font-weight: 500;
  position: absolute;
  left: 50%;
  transform: translateX(-50%) translateY(8px);
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
  transition-delay: 150ms;
  border-radius: 8px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 4px;
  padding-top: 4px;
  top: 100%;
  min-width: 190px;
  max-width: 350px;
  opacity: 0;
  pointer-events: none;
}

.markdown-wizard a:hover::after {
  opacity: 1;
}

.markdown-wizard a::before {
  content: "";
  background: var(--color-tooltip);
  position: absolute;
  left: 50%;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
  transition-delay: 150ms;
  transform: translateX(-50%) rotate(45deg) translateY(4px);
  top: 100%;
  border-radius: 4px;
  width: 20px;
  height: 20px;
  pointer-events: none;
  opacity: 0;
}

.markdown-wizard a:hover::before {
  opacity: 1;
}
