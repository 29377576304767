.appear-bottom {
	opacity: 0;
	margin-top: 100px;
	margin-bottom: -100px;

	animation: appear-bottom 1s ease-in-out 0s 1 normal forwards;
}

@keyframes appear-bottom {
	from {
		opacity: 0;
		margin-top: 100px;
		margin-bottom: -100px;
	}
	to {
		opacity: 1;
		margin-top: 0;
		margin-bottom: 0;
	}
}

.disappear-top {
	opacity: 1;
	margin-top: 0;
	margin-bottom: 0;

	animation: disappear-top 1s ease-in-out 0s 1 normal forwards;
}

@keyframes disappear-top {
	from {
		opacity: 1;
		margin-top: 0;
		margin-bottom: 0;
	}
	to {
		opacity: 0;
		margin-top: -100px;
		margin-bottom: 100px;
	}
}

.appear-bottom-opaque {
	opacity: 0;
	margin-top: 100px;
	margin-bottom: -100px;

	animation: appear-bottom-opaque 1s ease-in-out 0s 1 normal forwards;
}

@keyframes appear-bottom-opaque {
	from {
		opacity: 1;

		margin-top: 100px;
		margin-bottom: -100px;
	}
	to {
		opacity: 1;

		margin-top: 0;
		margin-bottom: 0;
	}
}

.appear-bottom-short {
	opacity: 0;
	margin-top: 20px;
	margin-bottom: -20px;

	animation: appear-bottom-short 0.3s ease-in-out 0s 1 normal forwards;
}

@keyframes appear-bottom-short {
	from {
		opacity: 0;
		margin-top: 20px;
		margin-bottom: -20px;
	}
	to {
		opacity: 1;
		margin-top: 0;
		margin-bottom: 0;
	}
}

.appear-bottom-short-opaque {
	opacity: 1;
	margin-top: 90px;
	margin-bottom: 90px;

	animation: appear-bottom-short-opaque 0.5s ease-in-out 0s 1 normal forwards;
}

@keyframes appear-bottom-short-opaque {
	from {
		opacity: 1;

		margin-top: 90px;
		margin-bottom: -90px;
	}
	to {
		opacity: 1;

		margin-top: 0;
		margin-bottom: 0;
	}
}

.appear-bottom-long {
	opacity: 1;
	margin-top: 500px;
	margin-bottom: 500px;

	animation: appear-bottom-long 1s ease-in-out 0s 1 normal forwards;
}

@keyframes appear-bottom-long {
	from {
		opacity: 1;
		margin-top: 500px;
		margin-bottom: 500px;
	}
	to {
		opacity: 1;
		margin-top: 0;
		margin-bottom: 0;
	}
}

.disappear-top-short {
	opacity: 1;
	margin-top: 0;
	margin-bottom: 0;

	animation: disappear-top-short 0.3s ease-in-out 0s 1 normal forwards;
}

@keyframes disappear-top-short {
	from {
		opacity: 1;
		margin-top: 0;
		margin-bottom: 0;
	}
	to {
		opacity: 0;
		margin-top: -20px;
		margin-bottom: 20px;
	}
}

.disappear-top-short-opaque {
	opacity: 1;
	margin-top: 0;
	margin-bottom: 0;

	animation: disappear-top-short-opaque 0.5s ease-in-out 0s 1 normal forwards;
}

@keyframes disappear-top-short-opaque {
	from {
		opacity: 1;
		margin-top: 0;
		margin-bottom: 0;
	}
	to {
		opacity: 1;
		margin-top: -100px;
		margin-bottom: 100px;
	}
}

.appear-in-place {
	opacity: 0;

	animation: appear-in-place 2s ease-in-out 0s 1 normal forwards;
}

@keyframes appear-in-place {
	from {
		opacity: 0;
	}
	50% {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
